﻿var maps = [];
var markers = [];
initMap = function() {
    const mapElems = document.getElementsByClassName("map");
    for(let i = 0; i < mapElems.length; i++) {
        let map = mapElems[i];

        const position = { lat: parseFloat(map.getAttribute('lat')), lng: parseFloat(map.getAttribute('lng')) };

        const mapDivId = map.getAttribute('id');

        maps[mapDivId] = new google.maps.Map(document.getElementById(mapDivId), {
            zoom: parseFloat(map.getAttribute('zoom')),
            center: position
        });

        markers[mapDivId] = new google.maps.Marker({
            position: position,
            map: maps[mapDivId]
        });
    }
}